import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RefBox = makeShortcode("RefBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hitabis steht für erfolgreiche IT-Projekte. So vielfältig wie unser Leistungsportfolio sind auch die Anforderungen unserer Kunden und  Projekte.`}</p>
    <p>{`Unsere IT-Lösungen berühren oft sensible Bereiche von Unternehmen, Strukturen und Produkten. Das große Vertrauen, das uns hier entgegengebracht wird, macht uns stolz. Allerdings können wir aus genau diesem Grund viele interessante und anspruchsvolle Projekte nicht uneingeschränkt auf unserer Webseite vorstellen: Unsere Kunden und deren konkrete Projektinhalte werden selbstverständlich vertraulich behandelt.`}</p>
    <p>{`Bitte kontaktieren Sie uns daher direkt, wenn Sie über eines unserer Projekte genauere Informationen benötigen. Gern senden wir Ihnen dann ausführlichere Projektreferenzen zu, die für Sie und Ihr Business relevant und vergleichbar sind. Oder wir stellen einen Kontakt zu einem Ansprechpartner unserer Auftraggeber her.`}</p>
    <h3>{`Einige unserer Referenzen in der Übersicht`}</h3>
    <RefBox anchor="oaklean" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6228f07449e78f351e8d1326944bfdb4/81b0c/logo-oaklean.avif 160w", "/static/6228f07449e78f351e8d1326944bfdb4/f13a9/logo-oaklean.avif 200w", "/static/6228f07449e78f351e8d1326944bfdb4/5f1e1/logo-oaklean.avif 340w", "/static/6228f07449e78f351e8d1326944bfdb4/3c285/logo-oaklean.avif 520w", "/static/6228f07449e78f351e8d1326944bfdb4/56a33/logo-oaklean.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/6228f07449e78f351e8d1326944bfdb4/60a22/logo-oaklean.webp 160w", "/static/6228f07449e78f351e8d1326944bfdb4/ba381/logo-oaklean.webp 200w", "/static/6228f07449e78f351e8d1326944bfdb4/8ae5a/logo-oaklean.webp 340w", "/static/6228f07449e78f351e8d1326944bfdb4/002e6/logo-oaklean.webp 520w", "/static/6228f07449e78f351e8d1326944bfdb4/e88ff/logo-oaklean.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6228f07449e78f351e8d1326944bfdb4/69538/logo-oaklean.png 160w", "/static/6228f07449e78f351e8d1326944bfdb4/772e8/logo-oaklean.png 200w", "/static/6228f07449e78f351e8d1326944bfdb4/9f933/logo-oaklean.png 340w", "/static/6228f07449e78f351e8d1326944bfdb4/69902/logo-oaklean.png 520w", "/static/6228f07449e78f351e8d1326944bfdb4/0a47e/logo-oaklean.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6228f07449e78f351e8d1326944bfdb4/0a47e/logo-oaklean.png",
              "alt": "Logo Oaklean",
              "title": "Logo Oaklean",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Oaklean`}</h3>
      <p>{`Green-Coding-Tool`}</p>
      <p>{`Entwicklung einer Open-Source-VS-Code-Integration zur Identifizierung von energieintensiven Codeabschnitten.`}</p>
      <p><a parentName="p" {...{
          "href": "https://oaklean.io/",
          "title": "Projekt anzeigen"
        }}>{`Projekt anzeigen`}</a></p>
    </RefBox>
    <RefBox anchor="zit" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/98444422bc819528bb2c2c93055e2fd5/81b0c/logo-zit-bb.avif 160w", "/static/98444422bc819528bb2c2c93055e2fd5/f13a9/logo-zit-bb.avif 200w", "/static/98444422bc819528bb2c2c93055e2fd5/5f1e1/logo-zit-bb.avif 340w", "/static/98444422bc819528bb2c2c93055e2fd5/3c285/logo-zit-bb.avif 520w", "/static/98444422bc819528bb2c2c93055e2fd5/56a33/logo-zit-bb.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/98444422bc819528bb2c2c93055e2fd5/60a22/logo-zit-bb.webp 160w", "/static/98444422bc819528bb2c2c93055e2fd5/ba381/logo-zit-bb.webp 200w", "/static/98444422bc819528bb2c2c93055e2fd5/8ae5a/logo-zit-bb.webp 340w", "/static/98444422bc819528bb2c2c93055e2fd5/002e6/logo-zit-bb.webp 520w", "/static/98444422bc819528bb2c2c93055e2fd5/e88ff/logo-zit-bb.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/98444422bc819528bb2c2c93055e2fd5/69538/logo-zit-bb.png 160w", "/static/98444422bc819528bb2c2c93055e2fd5/772e8/logo-zit-bb.png 200w", "/static/98444422bc819528bb2c2c93055e2fd5/9f933/logo-zit-bb.png 340w", "/static/98444422bc819528bb2c2c93055e2fd5/69902/logo-zit-bb.png 520w", "/static/98444422bc819528bb2c2c93055e2fd5/0a47e/logo-zit-bb.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/98444422bc819528bb2c2c93055e2fd5/0a47e/logo-zit-bb.png",
              "alt": "Logo Zentraler IT-Dienstleister Brandenburg",
              "title": "Logo Zentraler IT-Dienstleister Brandenburg",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Zentraler IT-Dienstleister Brandenburg`}</h3>
      <p>{`Informationstechnologie`}</p>
      <p>{`DB-Migration, -Installation, -Tuning; Administrationssupport für ein komplexes System einer Landesbehörde`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-ZITBB-Support.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="fiducia" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/09ccc289233cc7930396f2943fb5d6a3/81b0c/logo-fiducia.avif 160w", "/static/09ccc289233cc7930396f2943fb5d6a3/f13a9/logo-fiducia.avif 200w", "/static/09ccc289233cc7930396f2943fb5d6a3/5f1e1/logo-fiducia.avif 340w", "/static/09ccc289233cc7930396f2943fb5d6a3/3c285/logo-fiducia.avif 520w", "/static/09ccc289233cc7930396f2943fb5d6a3/56a33/logo-fiducia.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/09ccc289233cc7930396f2943fb5d6a3/60a22/logo-fiducia.webp 160w", "/static/09ccc289233cc7930396f2943fb5d6a3/ba381/logo-fiducia.webp 200w", "/static/09ccc289233cc7930396f2943fb5d6a3/8ae5a/logo-fiducia.webp 340w", "/static/09ccc289233cc7930396f2943fb5d6a3/002e6/logo-fiducia.webp 520w", "/static/09ccc289233cc7930396f2943fb5d6a3/e88ff/logo-fiducia.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/09ccc289233cc7930396f2943fb5d6a3/69538/logo-fiducia.png 160w", "/static/09ccc289233cc7930396f2943fb5d6a3/772e8/logo-fiducia.png 200w", "/static/09ccc289233cc7930396f2943fb5d6a3/9f933/logo-fiducia.png 340w", "/static/09ccc289233cc7930396f2943fb5d6a3/69902/logo-fiducia.png 520w", "/static/09ccc289233cc7930396f2943fb5d6a3/0a47e/logo-fiducia.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/09ccc289233cc7930396f2943fb5d6a3/0a47e/logo-fiducia.png",
              "alt": "Logo Fiducia IT AG",
              "title": "Logo Fiducia IT AG",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Fiducia IT AG`}</h3>
      <p>{`Banken & Versicherungen`}</p>
      <p>{`Konsolidierung und Migration unternehmenskritischer Datenbanksysteme auf aktuelle Versionen.`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-Fiducia-ADBS.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="bbsr" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/25ccd1e59c71a8c6b9e59c5a84c2899d/81b0c/logo-bbsr.avif 160w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/f13a9/logo-bbsr.avif 200w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/5f1e1/logo-bbsr.avif 340w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/3c285/logo-bbsr.avif 520w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/56a33/logo-bbsr.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/25ccd1e59c71a8c6b9e59c5a84c2899d/60a22/logo-bbsr.webp 160w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/ba381/logo-bbsr.webp 200w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/8ae5a/logo-bbsr.webp 340w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/002e6/logo-bbsr.webp 520w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/e88ff/logo-bbsr.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/25ccd1e59c71a8c6b9e59c5a84c2899d/69538/logo-bbsr.png 160w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/772e8/logo-bbsr.png 200w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/9f933/logo-bbsr.png 340w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/69902/logo-bbsr.png 520w", "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/0a47e/logo-bbsr.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/25ccd1e59c71a8c6b9e59c5a84c2899d/0a47e/logo-bbsr.png",
              "alt": "Logo Bundesinstitut für Bau-, Stadt- und Raumforschung",
              "title": "Logo Bundesinstitut für Bau-, Stadt- und Raumforschung",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Bundesinstitut für Bau-, Stadt- und Raumforschung`}</h3>
      <p>{`Öffentliche Auftraggeber`}</p>
      <p>{`Nachhaltiges Bauen - Konzeptentwicklung für ein EDV-gestütztes Bewertungs- und Dokumentations-instrument (eBNB)`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-BBSR-eBNB.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="generali" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/81b0c/logo-generali.avif 160w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/f13a9/logo-generali.avif 200w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/5f1e1/logo-generali.avif 340w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/3c285/logo-generali.avif 520w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/56a33/logo-generali.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/60a22/logo-generali.webp 160w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/ba381/logo-generali.webp 200w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/8ae5a/logo-generali.webp 340w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/002e6/logo-generali.webp 520w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/e88ff/logo-generali.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/69538/logo-generali.png 160w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/772e8/logo-generali.png 200w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/9f933/logo-generali.png 340w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/69902/logo-generali.png 520w", "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/0a47e/logo-generali.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1bb0ebe278d0c6f1a4d0e45f4564b7d6/0a47e/logo-generali.png",
              "alt": "Logo Boss Lab SA/Generali Versicherungen",
              "title": "Logo Boss Lab SA/Generali Versicherungen",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Boss Lab SA/Generali Versicherungen`}</h3>
      <p>{`Banken & Versicherungen`}</p>
      <p>{`Erstellung einer Archivdatenbank für Versicherungsverträge und Dokumente und Aufbau eines DVD-basierten externen Langzeitarchivs.`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-Generali-ADB.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="bbr" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/64fc3ca4a4bd6ba8d754b648b29d6644/81b0c/logo-bbr.avif 160w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/f13a9/logo-bbr.avif 200w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/5f1e1/logo-bbr.avif 340w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/3c285/logo-bbr.avif 520w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/56a33/logo-bbr.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/64fc3ca4a4bd6ba8d754b648b29d6644/60a22/logo-bbr.webp 160w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/ba381/logo-bbr.webp 200w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/8ae5a/logo-bbr.webp 340w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/002e6/logo-bbr.webp 520w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/e88ff/logo-bbr.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/64fc3ca4a4bd6ba8d754b648b29d6644/69538/logo-bbr.png 160w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/772e8/logo-bbr.png 200w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/9f933/logo-bbr.png 340w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/69902/logo-bbr.png 520w", "/static/64fc3ca4a4bd6ba8d754b648b29d6644/0a47e/logo-bbr.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/64fc3ca4a4bd6ba8d754b648b29d6644/0a47e/logo-bbr.png",
              "alt": "Logo Bundesamt für Bauwesen und Raumordnung",
              "title": "Logo Bundesamt für Bauwesen und Raumordnung",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Bundesamt für Bauwesen und Raumordnung`}</h3>
      <p>{`Öffentliche Auftraggeber`}</p>
      <p>{`Applikation für das Konjunkturprogramm II, mit dem Anträge elektronisch eingereicht und zentral verwaltet und bearbeitet werden.`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-BBR-KPII.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="jobberbee" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2aaedfab75eac7b3dc39a0c2d841677e/81b0c/logo-jobberbee.avif 160w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/f13a9/logo-jobberbee.avif 200w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/5f1e1/logo-jobberbee.avif 340w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/3c285/logo-jobberbee.avif 520w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/56a33/logo-jobberbee.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/2aaedfab75eac7b3dc39a0c2d841677e/60a22/logo-jobberbee.webp 160w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/ba381/logo-jobberbee.webp 200w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/8ae5a/logo-jobberbee.webp 340w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/002e6/logo-jobberbee.webp 520w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/e88ff/logo-jobberbee.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2aaedfab75eac7b3dc39a0c2d841677e/69538/logo-jobberbee.png 160w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/772e8/logo-jobberbee.png 200w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/9f933/logo-jobberbee.png 340w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/69902/logo-jobberbee.png 520w", "/static/2aaedfab75eac7b3dc39a0c2d841677e/0a47e/logo-jobberbee.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2aaedfab75eac7b3dc39a0c2d841677e/0a47e/logo-jobberbee.png",
              "alt": "Logo JobberBee AG",
              "title": "Logo JobberBee AG",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`JobberBee AG`}</h3>
      <p>{`IT-Unternehmen`}</p>
      <p>{`Aufbau einer JobPlattform mit automatisierter Jobempfehlung durch eine datenbankbasierte Matching-Engine.`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-JobberBee.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="tu" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a2bd5f7b748c0effedcff0484e39c725/81b0c/logo-tu-dresden.avif 160w", "/static/a2bd5f7b748c0effedcff0484e39c725/f13a9/logo-tu-dresden.avif 200w", "/static/a2bd5f7b748c0effedcff0484e39c725/5f1e1/logo-tu-dresden.avif 340w", "/static/a2bd5f7b748c0effedcff0484e39c725/3c285/logo-tu-dresden.avif 520w", "/static/a2bd5f7b748c0effedcff0484e39c725/56a33/logo-tu-dresden.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/a2bd5f7b748c0effedcff0484e39c725/60a22/logo-tu-dresden.webp 160w", "/static/a2bd5f7b748c0effedcff0484e39c725/ba381/logo-tu-dresden.webp 200w", "/static/a2bd5f7b748c0effedcff0484e39c725/8ae5a/logo-tu-dresden.webp 340w", "/static/a2bd5f7b748c0effedcff0484e39c725/002e6/logo-tu-dresden.webp 520w", "/static/a2bd5f7b748c0effedcff0484e39c725/e88ff/logo-tu-dresden.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a2bd5f7b748c0effedcff0484e39c725/69538/logo-tu-dresden.png 160w", "/static/a2bd5f7b748c0effedcff0484e39c725/772e8/logo-tu-dresden.png 200w", "/static/a2bd5f7b748c0effedcff0484e39c725/9f933/logo-tu-dresden.png 340w", "/static/a2bd5f7b748c0effedcff0484e39c725/69902/logo-tu-dresden.png 520w", "/static/a2bd5f7b748c0effedcff0484e39c725/0a47e/logo-tu-dresden.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a2bd5f7b748c0effedcff0484e39c725/0a47e/logo-tu-dresden.png",
              "alt": "Logo Technische Universität Dresden",
              "title": "Logo Technische Universität Dresden",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Technische Universität Dresden`}</h3>
      <p>{`Medizin & Pharmaindustrie`}</p>
      <p>{`Das elektronische Tagebuch hiDiary unterstützt die Pharmaindustrie bei der Erstellung und Durchführung klinischer Studien.`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-TU-Dresden-hiDiary.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="rbb" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4a820fe56ee0a66784e79d19a4eeea24/81b0c/logo-rbb.avif 160w", "/static/4a820fe56ee0a66784e79d19a4eeea24/f13a9/logo-rbb.avif 200w", "/static/4a820fe56ee0a66784e79d19a4eeea24/5f1e1/logo-rbb.avif 340w", "/static/4a820fe56ee0a66784e79d19a4eeea24/3c285/logo-rbb.avif 520w", "/static/4a820fe56ee0a66784e79d19a4eeea24/56a33/logo-rbb.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/4a820fe56ee0a66784e79d19a4eeea24/60a22/logo-rbb.webp 160w", "/static/4a820fe56ee0a66784e79d19a4eeea24/ba381/logo-rbb.webp 200w", "/static/4a820fe56ee0a66784e79d19a4eeea24/8ae5a/logo-rbb.webp 340w", "/static/4a820fe56ee0a66784e79d19a4eeea24/002e6/logo-rbb.webp 520w", "/static/4a820fe56ee0a66784e79d19a4eeea24/e88ff/logo-rbb.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4a820fe56ee0a66784e79d19a4eeea24/69538/logo-rbb.png 160w", "/static/4a820fe56ee0a66784e79d19a4eeea24/772e8/logo-rbb.png 200w", "/static/4a820fe56ee0a66784e79d19a4eeea24/9f933/logo-rbb.png 340w", "/static/4a820fe56ee0a66784e79d19a4eeea24/69902/logo-rbb.png 520w", "/static/4a820fe56ee0a66784e79d19a4eeea24/0a47e/logo-rbb.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4a820fe56ee0a66784e79d19a4eeea24/0a47e/logo-rbb.png",
              "alt": "Logo Rundfunk Berlin-Brandenburg",
              "title": "Logo Rundfunk Berlin-Brandenburg",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Rundfunk Berlin-Brandenburg`}</h3>
      <p>{`Medien`}</p>
      <p>{`Migration einer J2EE-Applikation von Oracle nach PostgreSQL, Performanceanalyse und -tuning`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-RBB-Migration-Postgres.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="gema" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/69d1ce23a278a972ba4808d6095cee3a/81b0c/logo-gema.avif 160w", "/static/69d1ce23a278a972ba4808d6095cee3a/f13a9/logo-gema.avif 200w", "/static/69d1ce23a278a972ba4808d6095cee3a/5f1e1/logo-gema.avif 340w", "/static/69d1ce23a278a972ba4808d6095cee3a/3c285/logo-gema.avif 520w", "/static/69d1ce23a278a972ba4808d6095cee3a/56a33/logo-gema.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/69d1ce23a278a972ba4808d6095cee3a/60a22/logo-gema.webp 160w", "/static/69d1ce23a278a972ba4808d6095cee3a/ba381/logo-gema.webp 200w", "/static/69d1ce23a278a972ba4808d6095cee3a/8ae5a/logo-gema.webp 340w", "/static/69d1ce23a278a972ba4808d6095cee3a/002e6/logo-gema.webp 520w", "/static/69d1ce23a278a972ba4808d6095cee3a/e88ff/logo-gema.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/69d1ce23a278a972ba4808d6095cee3a/69538/logo-gema.png 160w", "/static/69d1ce23a278a972ba4808d6095cee3a/772e8/logo-gema.png 200w", "/static/69d1ce23a278a972ba4808d6095cee3a/9f933/logo-gema.png 340w", "/static/69d1ce23a278a972ba4808d6095cee3a/69902/logo-gema.png 520w", "/static/69d1ce23a278a972ba4808d6095cee3a/0a47e/logo-gema.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/69d1ce23a278a972ba4808d6095cee3a/0a47e/logo-gema.png",
              "alt": "Logo Gema",
              "title": "Logo Gema",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Gema`}</h3>
      <p>{`Verwertungsgesellschaft`}</p>
      <p>{`Entwicklung von Software für interne Prozesse und zur Anteilsberechnung von künstlerischen Werken`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-GEMA.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="kaefer" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e80ce86d9353d6e7e011aa1646c66e8a/81b0c/logo-kaefer.avif 160w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/f13a9/logo-kaefer.avif 200w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/5f1e1/logo-kaefer.avif 340w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/3c285/logo-kaefer.avif 520w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/56a33/logo-kaefer.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/e80ce86d9353d6e7e011aa1646c66e8a/60a22/logo-kaefer.webp 160w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/ba381/logo-kaefer.webp 200w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/8ae5a/logo-kaefer.webp 340w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/002e6/logo-kaefer.webp 520w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/e88ff/logo-kaefer.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e80ce86d9353d6e7e011aa1646c66e8a/69538/logo-kaefer.png 160w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/772e8/logo-kaefer.png 200w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/9f933/logo-kaefer.png 340w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/69902/logo-kaefer.png 520w", "/static/e80ce86d9353d6e7e011aa1646c66e8a/0a47e/logo-kaefer.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e80ce86d9353d6e7e011aa1646c66e8a/0a47e/logo-kaefer.png",
              "alt": "Logo Hitabis GmbH",
              "title": "Logo Hitabis GmbH",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Hitabis GmbH`}</h3>
      <p>{`Informationstechnologie`}</p>
      <p>{`Konzeption und Implementierung einer Lösung zur projektbezogenen Zeiterfassung und Leistungsabrechnung`}</p>
      <p><a parentName="p" {...{
          "href": "/pdfs/Hitabis-Projektprofil-Kaefer.pdf",
          "title": "Projektdatenblatt herunterladen"
        }}>{`Projektdatenblatt (PDF)`}</a></p>
    </RefBox>
    <RefBox anchor="florida" mdxType="RefBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b212ad01c776286737161382c168b6c0/81b0c/logo-florida.avif 160w", "/static/b212ad01c776286737161382c168b6c0/f13a9/logo-florida.avif 200w", "/static/b212ad01c776286737161382c168b6c0/5f1e1/logo-florida.avif 340w", "/static/b212ad01c776286737161382c168b6c0/3c285/logo-florida.avif 520w", "/static/b212ad01c776286737161382c168b6c0/56a33/logo-florida.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/b212ad01c776286737161382c168b6c0/60a22/logo-florida.webp 160w", "/static/b212ad01c776286737161382c168b6c0/ba381/logo-florida.webp 200w", "/static/b212ad01c776286737161382c168b6c0/8ae5a/logo-florida.webp 340w", "/static/b212ad01c776286737161382c168b6c0/002e6/logo-florida.webp 520w", "/static/b212ad01c776286737161382c168b6c0/e88ff/logo-florida.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b212ad01c776286737161382c168b6c0/69538/logo-florida.png 160w", "/static/b212ad01c776286737161382c168b6c0/772e8/logo-florida.png 200w", "/static/b212ad01c776286737161382c168b6c0/9f933/logo-florida.png 340w", "/static/b212ad01c776286737161382c168b6c0/69902/logo-florida.png 520w", "/static/b212ad01c776286737161382c168b6c0/0a47e/logo-florida.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b212ad01c776286737161382c168b6c0/0a47e/logo-florida.png",
              "alt": "Logo Florida-Eis Manufaktur GmbH",
              "title": "Logo Florida-Eis Manufaktur GmbH",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Florida-Eis Manufaktur GmbH`}</h3>
      <p>{`Kooperation "Die smarte Truhe"`}</p>
      <p>{`Mit der Smarten Truhe in die Zukunft starten: Der perfekte Genuss der nächsten Generation. Intelligente Technologie der Hitabis GmbH trifft auf die gewohnte Eiscreme-Qualität von Florida Eis.`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.floridaeis.de//",
          "title": "Projekt anzeigen"
        }}>{`Projekt anzeigen`}</a></p>
    </RefBox>
    <h1></h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      